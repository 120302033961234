$small: 300px;
$medium: 900px;

$primary: #b1a197;
$primary-light: #b1a197ab;
$primary-hover: #b1a1976c;
$icon-background: #f8f5f3;

$gray-1: #444444;
$gray-2: #3b434a;

$white: #fff;
