@import "../../config.scss";

#portfolio-hero {
  width: 100%;
  height: 40vh;
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url("../../../public/assets/img/portfolio/portfolio-header.jpg");
  background-size: cover;
  position: relative;
  background-color: black;
  background-position: center 25%;
}

.masonry-photo-frame {
  padding: 20px;
  box-sizing: border-box;

  img {
    width: 100%;
  }

  iframe {
    height: 700px;
    width: 100%;

    @media screen and (max-width: $medium) {
      height: 560px;
    }
  }
}

.masonry-photo-frame-video {
  padding: 20px;
  box-sizing: border-box;
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 50vh;

  img {
    // width: 100%;
  }

  iframe {
    // height: 700px;
    // width: auto;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: calc(100% - 20px) !important;
    height: 100%;
    min-width: 0 !important;
    margin: 20px 0;

    @media screen and (max-width: $medium) {
      width: 100% !important;
    }
  }
  @media screen and (max-width: $medium) {
    height: 100vh;
  }
}
