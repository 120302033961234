#loader {
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: white;
  z-index: 99999;
}

.three-bounce {
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -11px 0 0 -31px;
}
.three-bounce div {
  background: #fff;
  display: inline-block;
  height: 18px;
  width: 18px;
  border-radius: 100%;
  -webkit-animation: bouncedelay 1.4s infinite ease-in-out;
  animation: bouncedelay 1.4s infinite ease-in-out;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}
.three-bounce div.one {
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}
.three-bounce div.two {
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}
@-webkit-keyframes bouncedelay {
  0%,
  100%,
  80% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
@keyframes bouncedelay {
  0%,
  100%,
  80% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
#loader .three-bounce div {
  background: #b1a197;
}
