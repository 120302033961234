@import "../../config.scss";

#development-hero {
  width: 100%;
  height: 40vh;
  background: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
    url("../../../public/assets/img/laptop.jpg") center 60%;
  background-size: cover;
  position: relative;
  text-align: center;
}

#projects {
  .container {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    width: 100%;
    position: relative;
    overflow: hidden;
    columns: 2;
    justify-content: center;

    .project {
      //   padding: 20px;
      //   width: 50%;
      //   flex-shrink: 1;
      width: 80%;
      height: 200px;
      display: flex;
      position: relative;

      box-sizing: border-box;
      overflow: hidden;

      .image-wrapper {
        position: relative;
        width: 30%;
        height: 100%;
        overflow: hidden;
      }

      .title {
        position: relative;
        bottom: 0;
        padding: 10px;
        // background: rgba(0, 0, 0, 0.5);
        width: 70%;
        padding: 20px;

        h4 {
          //   height: 30px;
          margin: 0;
          width: 100%;
          overflow: hidden;
          color: black;
        }
      }

      img {
        width: 100%;
        height: 100%;
        position: absolute;
        box-sizing: border-box;
        // transform: translate(0, -25%);
        object-fit: cover;
      }
    }

    @media screen and (max-width: $medium) {
      .article {
        flex-direction: column-reverse;

        .title {
          width: 100%;

          h4 {
            padding: 0;
            font-size: 16px;

            font-weight: 600;
          }
        }

        .image-wrapper {
          width: 100%;
        }
      }
    }
  }
}

.article-content {
  width: 100%;
  overflow: hidden;

  img {
    display: flex;
    height: auto;
    margin: 0 auto;
    max-width: 800px;
    width: auto;
    max-height: 500px;

    @media screen and (max-width: $medium) {
      width: 100%;
      max-height: fit-content;
    }
  }

  iframe {
    width: 100%;
    height: auto;
    min-height: 500px;
  }
}

.project-image {
  margin-bottom: 20px;
  width: 100%;
}
