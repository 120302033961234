#resume-hero {
  width: 100%;
  height: 40vh;
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url("../../../public/assets/img/mountains.jpg") center 40%;
  background-size: cover;
  position: relative;
}

#resume {
  .headshot {
    width: 100%;

    span {
      width: 180px;
      position: relative;
      top: 0;
    }
  }

  .biodata {
    padding-top: 20px;
  }
}
